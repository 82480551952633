<template>
  <a-cascader
    :value="value"
    :options="data"
    @change="onChange"
    change-on-select
    expand-trigger="hover"
    :field-names="fieldNames"
    placeholder="请选择上级"
  />
</template>
<script>
export default {
  data() {
    return {
      id: [],
      fieldNames: {
        label: 'name',
        value: 'id',
        children: 'children'
      },
      value: []
    }
  },
  props: {
    data: {
      type: Array,
      default: function() {
        return []
      }
    },
    menuItemId: {
      type: Number,
      default: 0
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.familyTree()
  },
  methods: {
    familyTree() {
      // 查找所有父级id
      const id = this.menuItemId
      console.log(id)

      let arr = window.sessionStorage.getItem('allMenuArr')
      arr = JSON.parse(arr)
      if (id !== '0') {
        const idArr = []
        const forFn = function(val) {
          arr.forEach((item) => {
            if (item.id === val) {
              idArr.push(item.id)
              forFn(item.parent_id)
            }
          })
        }
        forFn(id)
        idArr.reverse() // 颠倒排序
        this.value = idArr
      }
    },
    onChange(e) {
      // 选择器选择事件
      this.value = e
      this.$emit('change', e[e.length - 1])
    }
  },
  watch: {
    menuItemId(val) {
      if (val) {
        this.familyTree()
      } else {
        this.value = []
      }
    },
    visible(val) {
      if (!val) {
        this.value = []
      }
    }
  }
}
</script>
