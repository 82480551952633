<template>
  <a-drawer
    :title="title"
    :width="width"
    :body-style="{ padding: padding }"
    :visible="visible"
    :closable="false"
    @close="onClose"
  >
    <slot />
  </a-drawer>
</template>
<script>
export default {
  data() {
    return {}
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    visible: {
      type: Boolean,
      default: false
    },
    width: {
      type: Number,
      default: 720
    },
    padding: {
      type: String,
      default: '24px 24px 108px 24px'
    }
  },
  methods: {
    onClose() {
      this.$emit('onClose')
    }
  }
}
</script>
<style>
.ant-drawer .ant-drawer-body {
  padding: 24px 24px 108px 24px;
}

.ant-drawer-body .ant-form {
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
}

.drawer-btn-wrap {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 72px;
  width: 100%;
  border-top: 1px solid #ebebeb;
  padding: 16px 24px;
  background-color: #fff;
  text-align: right;
}

.drawer-btn-wrap .ant-btn {
  width: 64px;
  height: 40px;
  padding: 0;
}

.drawer-btn-wrap .ant-btn:nth-child(1) {
  margin-right: 16px;
}
</style>
