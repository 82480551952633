<template>
  <!-- 权限管理-菜单管理 -->
  <div class="container">
    <div class="main-content">
      <div class="main-content-header">
        <div class="item">
          <div class="title">菜单管理</div>
        </div>
        <div class="item">
          <a-button type="primary" icon="plus" @click="handleDrawerOpen">
            新建
          </a-button>
        </div>
      </div>
      <div class="table-wrap">
        <a-table
          :columns="columns"
          :data-source="dataSource"
          :pagination="false"
          :row-key="(record) => record.id"
          :loading="loading"
          :scroll="{ x: 600 }"
        >
          <template slot="icon" slot-scope="record">
            <div class="icon">
              <my-icon :type="record.icon" />
            </div>
          </template>
          <template slot="action" slot-scope="record">
            <a-button type="link" @click="handleDrawerEdit(record)">
              编辑
            </a-button>
            <a-button type="link" @click="handleDelete(record.id)">
              删除
            </a-button>
          </template>
        </a-table>
      </div>
    </div>

    <drawer title="新建菜单" :visible="visible">
      <a-form :form="drawerForm" layout="vertical" @submit="handleDrawerSubmit">
        <div class="form-item">
          <a-form-item label="菜单名称">
            <a-input
              v-decorator="[
                'name',
                {
                  rules: [{ required: true, message: '请输入菜单名称' }]
                }
              ]"
              placeholder="请输入菜单名称"
            />
          </a-form-item>
          <a-form-item label="排序">
            <a-input
              v-decorator="[
                'sort',
                {
                  rules: [{ required: true, message: '请输入菜单排序' }]
                }
              ]"
              type="number"
              placeholder="请输入菜单排序"
            />
          </a-form-item>
        </div>
        <div class="form-item">
          <a-form-item label="上级">
            <menu-cascader
              v-decorator="['parent_id']"
              :data="dataSource"
              :menu-item-id="parent_id"
              :visible="menuCascaderVisible"
            />
          </a-form-item>
          <a-form-item label="路径">
            <a-input v-decorator="['url']" placeholder="请输入菜单路径" />
          </a-form-item>
        </div>
        <div class="form-item">
          <a-form-item label="Api">
            <a-input v-decorator="['api']" placeholder="请输入菜单Api" />
          </a-form-item>
          <a-form-item label="图标">
            <a-input v-decorator="['icon']" placeholder="请输入菜单图标" />
          </a-form-item>
        </div>
        <div class="form-item">
          <a-form-item label="导航栏是否可见">
            <a-switch v-decorator="['visible', { valuePropName: 'checked' }]" />
          </a-form-item>
        </div>
        <div class="form-item-border-top">
          <a-form-item label="按钮">
            <a-checkbox-group
              v-model="pageButtons"
              name="checkboxgroup"
              :options="$pageButtons"
            />
          </a-form-item>
        </div>
        <div class="drawer-btn-wrap">
          <a-button @click="handleDrawerClose">
            取消
          </a-button>
          <a-button type="primary" html-type="submit">
            提交
          </a-button>
        </div>
      </a-form>
    </drawer>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import drawer from '@/components/drawer/index'
import menuCascader from '@/components/menu-cascader/index'
import { Icon } from 'ant-design-vue'
const MyIcon = Icon.createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/font_2401787_bilk8r4mgq.js' // 在 iconfont.cn 上生成
})
let menuItemId = 0 // 当前编辑项id
export default {
  data() {
    return {
      columns: [
        {
          title: '菜单名称',
          dataIndex: 'name',
          width: 300
        },
        {
          title: '排序',
          dataIndex: 'sort',
          width: 200
        },
        {
          title: '图标',
          scopedSlots: { customRender: 'icon' }
        },
        {
          title: '操作',
          fixed: 'right',
          width: 200,
          scopedSlots: { customRender: 'action' }
        }
      ],
      dataSource: [],
      loading: true,
      visible: false,
      drawerForm: this.$form.createForm(this),
      menuId: '',
      parent_id: 0,
      menuCascaderVisible: false,
      pageButtons: []
    }
  },
  computed: {
    ...mapState(['pageTitle'])
  },
  components: {
    drawer,
    MyIcon,
    menuCascader
  },
  created() {
    this.getAllRoleMenuControls()
  },
  methods: {
    onSearch() {},
    async getAllRoleMenuControls() {
      // 获取所有权限列表
      const data = await this.$axios.getAllRoleMenuControls()
      this.dataSource = data
      this.loading = false
    },
    handleDelete(id) {
      // 删除按钮点击事件
      const item = this.dataSource.find((e) => e.id === id)
      if (item) {
        if (item.children) {
          this.$message.error('请先删除下级菜单！')
          return
        }
      }
      this.$confirm({
        title: '确定删除该权限?',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          const data = {
            id: id
          }
          this.$axios.delAllRoleMenuControl(data).then(() => {
            this.$message.success('操作成功')
            this.getAllRoleMenuControls()
          })
        }
      })
    },
    handleDrawerOpen() {
      // 打开
      this.menuCascaderVisible = true
      this.visible = true
      setTimeout(() => {
        this.drawerForm.setFieldsValue({
          visible: true
        })
      }, 300)
    },
    handleDrawerEdit(e) {
      // 编辑
      if (e.parent_id) {
        this.parent_id = e.parent_id
      }
      menuItemId = e.id

      if (e.buttons.length) {
        e.buttons.forEach((e) => {
          const item = this.$pageButtons.find((n) => n.value === e.value)
          if (item) {
            this.pageButtons.push(item.value)
          }
        })
      }

      this.menuCascaderVisible = true
      this.visible = true
      this.$nextTick(() => {
        this.drawerForm.setFieldsValue({
          name: e.name,
          url: e.url,
          sort: e.sort,
          api: e.api,
          parent_id: e.parent_id,
          visible: e.visible
        })
      })
    },
    handleDrawerClose() {
      // 关闭表单抽屉
      this.visible = false
      menuItemId = 0
      this.parent_id = 0
      this.pageButtons = []
      this.menuCascaderVisible = false
      this.drawerForm.resetFields()
    },
    handleDrawerSubmit(e) {
      // 提交表单数据
      e.preventDefault()
      this.drawerForm.validateFields((err, values) => {
        if (!err) {
          this.confirm(values)
        }
      })
    },
    confirm(e) {
      // 提交
      if (!e.url) e.url = ''
      if (!e.api) e.api = ''
      if (!e.parent_id) e.parent_id = 0
      const buttons = []
      if (this.pageButtons.length) {
        this.pageButtons.forEach((e) => {
          const item = this.$pageButtons.find((n) => n.value === e)
          if (item) {
            buttons.push(item)
          }
        })
      }
      e.buttons = buttons
      if (menuItemId) {
        e.id = menuItemId
        this.$axios.editAllRoleMenuControl(e).then(() => {
          this.$message.success('操作成功')
          this.handleDrawerClose()
          this.getAllRoleMenuControls()
        })
      } else {
        this.$axios.addAllRoleMenuControl(e).then(() => {
          this.$message.success('操作成功')
          this.handleDrawerClose()
          this.getAllRoleMenuControls()
        })
      }
    }
  }
}
</script>

<style scoped>
.title-box {
  /* height: ; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
}

.icon {
  display: inline-block;
  padding: 0 10px;
  background-color: #1890ff;
  font-size: 18px;
}
</style>
